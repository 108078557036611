import { Flex } from "rebass";

// Components
import { Error } from "../components";

const ErrorPage = ({ statusCode }) => {
	return (
		<Error
			message={statusCode === 404 ? "Page not found." : null}
			statusCode={statusCode}
		/>
	);
};

ErrorPage.getInitialProps = ({ res, err }) => {
	const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
	return { statusCode };
};

export default ErrorPage;
